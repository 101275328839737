import { TableState } from '../../../common/types';

export const constructQueryVariables = <E>(tableState: TableState<E>) => {
  const searchConditions =
    tableState.searchTerm.length > 0
      ? [
          {
            _or: [{ name: { _ilike: `%${tableState.searchTerm}%` } }],
          },
        ]
      : [];
  return {
    limit: tableState.rowsPerPage,
    offset: tableState.rowsPerPage * tableState.page,
    name_order: tableState.orderBy === 'name' ? tableState.order : undefined,
    status_order:
      tableState.orderBy === 'status' ? tableState.order : undefined,
    created_at_order:
      tableState.orderBy === 'created_at' ? tableState.order : undefined,
    updated_at_order:
      tableState.orderBy === 'updated_at' ? tableState.order : undefined,
    id_order: tableState.orderBy === 'id' ? tableState.order : undefined,
    where: {
      _and: [{ deleted_at: { _is_null: true } }, ...searchConditions],
    },
  };
};
