import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { useForm, useFormState, useWatch, FieldError } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import reduce from 'lodash/reduce';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
  usePressQuery,
  PressesDocument,
  useDeletePressByIdMutation,
  useUpdatePressByIdMutation,
  PressCountDocument,
} from '../../../api/graphql';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import RichTextField from '../../../components/Inputs/RichTextField';
import { Nullable } from '../../../common/types';

interface PressEditForm {
  id: number;
  title: string;
  body: object;
  show_more_text?: Nullable<string>;
  show_more_url?: Nullable<string>;
  video?: Nullable<string>;
  audio?: Nullable<string>;
}

const PressEditSchema: yup.SchemaOf<PressEditForm> = yup.object({
  id: yup.number().integer().required(),
  title: yup.string().min(3).required(),
  body: yup
    .object()
    .test({
      test: (value: any) => {
        if (value) {
          return value.getCurrentContent().getPlainText().length >= 50;
        }
        return false;
      },
      message: 'Body must be longer then 50 characters',
    })
    .required()
    .label('Body'),
  show_more_text: yup.string(),
  show_more_url: yup.string().url().label('Show more URl'),
  video: yup.string(),
  audio: yup.string(),
});

const constructModalBody = (press?: any) =>
  `Are you sure you want to remove a press entry with title ${press?.title}`;

const parseValues = (values: any) =>
  reduce<any, PressEditForm>(
    values,
    (acc, val, key) => {
      if (key === '__typename') return acc;

      return {
        ...acc,
        [key]: val ?? '',
      };
    },
    {} as PressEditForm
  );

const EditPress: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: press, loading } = usePressQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = useForm<PressEditForm>({
    resolver: yupResolver(PressEditSchema),
    mode: 'onChange',
    defaultValues: {
      body: EditorState.createEmpty(),
      title: '',
      show_more_url: '',
      show_more_text: '',
      video: '',
      audio: '',
    },
  });
  const { isDirty } = useFormState({
    control,
    name: ['audio', 'video', 'show_more_text', 'show_more_url', 'title'],
  });

  const { body } = useWatch({ control });
  const isEditorDirty = press
    ? JSON.stringify(
        convertToRaw((body as EditorState).getCurrentContent())
      ) !== press.press_by_pk!.body
    : false;

  const [updatePressByIdRequest, { loading: updateLoading }] =
    useUpdatePressByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Press successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [deletePressRequest, { loading: deletePressByIdLoading }] =
    useDeletePressByIdMutation({
      onCompleted: () => {
        setIsRemovingItem(false);
        SnackbarManager.addSnackbar({
          message: `Press with ID ${press?.press_by_pk?.id} successfully deleted`,
          options: { variant: 'success' },
        });
        navigate('/dashboard/press');
      },
      onError: () => {
        setIsRemovingItem(false);
      },
      refetchQueries: [
        PressesDocument,
        'presses',
        PressCountDocument,
        'pressCount',
      ],
    });

  useEffect(() => {
    if (press && press.press_by_pk) {
      const { press_by_pk } = press;
      const parsedValues = parseValues(press_by_pk);
      const parsed = {
        ...parsedValues,
        body: EditorState.createWithContent(
          convertFromRaw(JSON.parse(press_by_pk!.body))
        ),
        created_at: format(parseISO(press_by_pk?.created_at), 'MM-dd-yyyy'),
        updated_at: format(parseISO(press_by_pk?.updated_at), 'MM-dd-yyyy'),
      };
      reset(parsed);
    }
  }, [press?.press_by_pk, loading, reset]);

  const updatePress = (formData: PressEditForm) => {
    const serializedData = {
      ...formData,
      body: JSON.stringify(
        convertToRaw((formData.body as EditorState).getCurrentContent())
      ),
    };
    updatePressByIdRequest({
      variables: serializedData,
    });
  };

  const promptPressDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deletePressRequest({ variables: { id: press!.press_by_pk!.id } });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <Box component="form" onSubmit={handleSubmit(updatePress)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
          />
          <RichTextField
            name="body"
            label="Body"
            error={!!errors.body}
            helperText={(errors.body as FieldError)?.message}
            control={control}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="show_more_text"
            control={control}
            margin="normal"
            id="show_more_text"
            label="Show More Text"
            error={!!errors.show_more_text}
            helperText={errors.show_more_text?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="show_more_url"
            control={control}
            margin="normal"
            id="show_more_url"
            label="Show More URL"
            error={!!errors.show_more_url}
            helperText={errors.show_more_url?.message}
            sx={{ flexGrow: '1' }}
          />

          <TextField
            name="video"
            control={control}
            margin="normal"
            id="video"
            label="Video"
            error={!!errors.video}
            helperText={errors.video?.message}
            sx={{ flexGrow: '1' }}
          />

          <TextField
            name="audio"
            control={control}
            margin="normal"
            id="audio"
            label="Audio"
            error={!!errors.audio}
            helperText={errors.audio?.message}
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || (!isDirty && !isEditorDirty)}
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptPressDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deletePressByIdLoading}
          content={{
            body: constructModalBody(press!.press_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Press',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditPress;
