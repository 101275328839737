import { TableState } from '../../../common/types';

export const constructQueryVariables = <E>(tableState: TableState<E>) => {
  const searchConditions =
    tableState.searchTerm.length > 0
      ? [
          {
            _or: [
              {
                profile: {
                  owner: {
                    _or: [
                      { email: { _ilike: `%${tableState.searchTerm}%` } },
                      {
                        display_name: { _ilike: `%${tableState.searchTerm}%` },
                      },
                    ],
                  },
                },
              },
            ],
          },
        ]
      : [];
  return {
    limit: tableState.rowsPerPage,
    offset: tableState.rowsPerPage * tableState.page,
    id_order: tableState.orderBy === 'id' ? tableState.order : undefined,
    created_at_order:
      tableState.orderBy === 'created_at' ? tableState.order : undefined,
    updated_at_order:
      tableState.orderBy === 'updated_at' ? tableState.order : undefined,
    location_order:
      tableState.orderBy === 'location' ? tableState.order : undefined,
    status_order:
      tableState.orderBy === 'status' ? tableState.order : undefined,
    email_order: tableState.orderBy === 'email' ? tableState.order : undefined,
    display_name_order:
      tableState.orderBy === 'display_name' ? tableState.order : undefined,
    gender_order:
      tableState.orderBy === 'gender' ? tableState.order : undefined,
    is_certified_order:
      tableState.orderBy === 'is_certified' ? tableState.order : undefined,
    is_trained_order:
      tableState.orderBy === 'is_trained' ? tableState.order : undefined,
    where: {
      _and: [{ deleted_at: { _is_null: true } }, ...searchConditions],
    },
  };
};
