import Edit from '@mui/icons-material/Edit';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Button,
  TableSortLabel,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import {
  Order_By,
  Press,
  PressCountDocument,
  PressesDocument,
  useDeletePressByIdMutation,
  usePressCountQuery,
  usePressesLazyQuery,
} from '../../../api/graphql';
import { commonColumns, defaultTableState } from '../../../common/constants';
import { Column, TableState } from '../../../common/types';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Link from '../../../components/Link';
import { convertOrder } from '../../../common/utils';
import { constructQueryVariables } from './utils';

const columns: readonly Column[] = [
  ...commonColumns,
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
  },
  { id: 'actions', label: 'Actions', align: 'right' },
];

const constructModalBody = (press?: Press | null) =>
  `Are you sure you want to remove press entry with title ${press?.title}`;

export const PressPage: React.FC = () => {
  const [tableState, setTableState] =
    useState<TableState<Press>>(defaultTableState);

  const { data: count, loading: countLoading } = usePressCountQuery();
  const [fetchPresses, { data, loading, called }] = usePressesLazyQuery();
  const throttledFetchPresses = useRef(throttle(fetchPresses, 500));

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({ ...tableState, searchTerm: event.target.value });
    const term = event.target.value;
    throttledFetchPresses.current({
      variables: constructQueryVariables({ ...tableState, searchTerm: term }),
    });
  };

  useEffect(() => {
    fetchPresses({
      variables: constructQueryVariables(tableState),
    });
  }, [fetchPresses]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = tableState.orderBy === property && tableState.order === 'asc';
    const nextOrder = isAsc ? Order_By.Desc : Order_By.Asc;
    setTableState({ ...tableState, order: nextOrder, orderBy: property });
    fetchPresses({
      variables: constructQueryVariables({
        ...tableState,
        order: nextOrder,
        orderBy: property,
      }),
    });
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const [deletePressRequest, { loading: deletePressByIdLoading }] =
    useDeletePressByIdMutation({
      onCompleted: () => {
        setTableState({ ...tableState, isRemovingItem: false });
      },
      onError: () => {
        setTableState({ ...tableState, isRemovingItem: false });
      },
      refetchQueries: [
        PressesDocument,
        'presses',
        PressCountDocument,
        'pressCount',
      ],
    });

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableState({ ...tableState, page: newPage });
    fetchPresses({
      variables: constructQueryVariables({ ...tableState, page: newPage }),
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableState({ ...tableState, rowsPerPage: +event.target.value, page: 0 });
    fetchPresses({
      variables: constructQueryVariables({
        ...tableState,
        rowsPerPage: +event.target.value,
        page: 0,
      }),
    });
  };

  const deletePress = (press: Press): void => {
    setTableState({
      ...tableState,
      isRemovingItem: true,
      removableItem: press,
    });
  };

  const onDeleteApprove = () => {
    deletePressRequest({ variables: { id: tableState.removableItem!.id } });
  };
  const onDeleteDeny = () =>
    setTableState({ ...tableState, isRemovingItem: false });
  const onDeleteClose = () =>
    setTableState({ ...tableState, isRemovingItem: false });

  return (
    <>
      <Link to="create">
        <Button fullWidth variant="contained" sx={{ marginBottom: 2 }}>
          Create Press Entry
        </Button>
      </Link>
      <TextField
        id="search"
        label="Search"
        fullWidth
        value={tableState.searchTerm}
        onChange={onSearchChange}
        sx={{ marginBottom: 2 }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'calc(100vh - 20px)', maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={tableState.orderBy === column.id}
                        direction={
                          tableState.orderBy === column.id
                            ? convertOrder(tableState.order)
                            : 'asc'
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && called
                ? data!.press_aggregate.nodes.map((row: any) => {
                    // Fix this any later
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = column.valuePath
                            ? get(row, column.valuePath)
                            : row[column.id];
                          const formattedValue = column.format
                            ? column.format(value)
                            : value;
                          if (column.id === 'actions')
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Link to={`edit/${row.id}`}>
                                  <IconButton aria-label="edit-item">
                                    <Edit />
                                  </IconButton>
                                </Link>
                                <IconButton
                                  aria-label="delete-item"
                                  onClick={() => deletePress(row)}
                                >
                                  <DeleteOutlined />
                                </IconButton>
                              </TableCell>
                            );

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.children
                                ? column.children(formattedValue)
                                : formattedValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {countLoading ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count!.press_aggregate.aggregate!.count}
            rowsPerPage={tableState.rowsPerPage}
            page={tableState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <ConfirmationModal
          open={tableState.isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deletePressByIdLoading}
          content={{
            body: constructModalBody(tableState.removableItem),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Press',
          }}
        />
      </Paper>
    </>
  );
};

export default PressPage;
