import React, { PropsWithChildren, useMemo } from 'react';
import useAuthenticated from '../hooks/useAuthenticated';

type PermissionContextType = {
  // eslint-disable-next-line no-unused-vars
  isAllowedTo: (roles: string[]) => boolean;
};

const defaultBehavior: PermissionContextType = {
  isAllowedTo: () => false,
};

export const PermissionContext = React.createContext(defaultBehavior);

const PermissionProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { user } = useAuthenticated();
  const isAllowedTo = useMemo(
    () => (checkedRoles: string[]) =>
      user
        ? [user.role].some((role: string) =>
            checkedRoles.some((assignedRole) => assignedRole === role)
          )
        : false,
    [user]
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
