import React from 'react';
import { Box, Typography } from '@mui/material';

const DashboardIndexPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
      component="div"
    >
      <Typography>Select a section to edit data</Typography>
    </Box>
  );
};

export default DashboardIndexPage;
