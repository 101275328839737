import React from 'react';
import format from 'date-fns/format';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  PractitionerReviewsDocument,
  usePractitionerReviewsQuery,
  useReviewProfileMutation,
} from '../../../api/graphql';
import Loader from '../../../components/Loader';
import TextField from '../../../components/Inputs/TextField';
import SnackbarManager from '../../../components/SnackbarManager/manager';

interface ReviewPractitionerForm {
  comment: string;
}

const ReviewPractitionerSchema: yup.SchemaOf<ReviewPractitionerForm> =
  yup.object({
    comment: yup.string().min(10).required().label('Review'),
  });

const ReviewPractitioner = () => {
  const { id } = useParams();
  const { data: profileReviews, loading } = usePractitionerReviewsQuery({
    variables: {
      id: parseInt(id!, 10),
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { comment: '' },
    resolver: yupResolver(ReviewPractitionerSchema),
  });
  const [reviewProfile, { loading: uploadingReview }] =
    useReviewProfileMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Review successfully added',
          options: { variant: 'success' },
        });
      },
      onError: () => {
        SnackbarManager.addSnackbar({
          message: 'Unable to add snackbar',
          options: { variant: 'error' },
        });
      },
      refetchQueries: [PractitionerReviewsDocument, 'practitionerReviews'],
    });

  const reviewPractitioner = (formData: ReviewPractitionerForm) => {
    const { comment } = formData;
    reviewProfile({
      variables: {
        comment,
        profile_id: parseInt(id!, 10),
      },
    });
  };

  if (loading || uploadingReview) return <Loader />;

  return (
    <Box component="main">
      {profileReviews?.profile_review.length === 0 ? (
        <Typography>No reviews found yet</Typography>
      ) : (
        <Typography>{`${profileReviews?.profile_review[0].profile?.owner.display_name}'s Reviews`}</Typography>
      )}
      <Box component="div" sx={{ marginTop: '10px', marginBottom: '10px' }}>
        {profileReviews?.profile_review.map((review) => (
          <Accordion key={review.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Reviewed by {review.reviewer?.display_name} on{' '}
                {format(Date.parse(review.created_at), 'MMM dd, yyyy h:mm b')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{review.comment}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box component="form" onSubmit={handleSubmit(reviewPractitioner)}>
        <FormControl fullWidth>
          <FormLabel>Review Practitioner</FormLabel>
          <TextField
            name="comment"
            control={control}
            margin="normal"
            id="comment"
            label="Review"
            error={!!errors.comment}
            helperText={errors.comment?.message}
            sx={{ flexGrow: '1' }}
            multiline
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !isDirty}
            fullWidth
          >
            Publish review
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ReviewPractitioner;
