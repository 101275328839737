import { EventEmitter } from 'eventemitter3';
import { noop } from 'lodash';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMeQuery } from '../api/graphql';
import storage from '../storage';

export interface AuthenticatedUser {
  id: number;
  email: string;
  display_name: string;
  role: string;
}

interface UserContextValues {
  user: AuthenticatedUser | null | undefined;
  // eslint-disable-next-line no-unused-vars
  setUser: (user: AuthenticatedUser | null) => void;
  isAuthenticating: boolean;
}

export const UserContext = createContext<UserContextValues>({
  user: null,
  setUser: noop,
  isAuthenticating: false,
});

export const AuthEventEmitter = new EventEmitter();

const UserContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  useMeQuery({
    onCompleted: (queryResults) => {
      setUser(queryResults.me);
      setIsAuthenticating(false);
    },
    onError: () => setIsAuthenticating(false),
  });
  // eslint-disable-next-line no-shadow
  const userSetter = (user: AuthenticatedUser | null) => setUser(user);

  useEffect(() => {
    AuthEventEmitter.on('auth', (event: string) => {
      if (event === 'logout') {
        storage.removeAuthTokens();
        setUser(null);
      }
    });

    return () => {
      AuthEventEmitter.removeListener('toast');
    };
  }, []);

  const values = useMemo(
    // eslint-disable-next-line no-shadow
    () => ({ user, isAuthenticating, setUser: userSetter }),
    [isAuthenticating, user]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
