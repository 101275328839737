import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  TableSortLabel,
  TextField,
  Button,
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import throttle from 'lodash/throttle';
import { Column, TableState } from '../../../common/types';
import { commonColumns, defaultTableState } from '../../../common/constants';
import {
  Order_By,
  useDeleteUserByIdMutation,
  User,
  UsersCountDocument,
  UsersDocument,
  useUsersCountQuery,
  useUsersLazyQuery,
} from '../../../api/graphql';
import Link from '../../../components/Link';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { convertOrder } from '../../../common/utils';
import { constructQueryVariables } from './utils';

const columns: readonly Column[] = [
  ...commonColumns,
  { id: 'first_name', label: 'First Name', minWidth: 130 },
  { id: 'last_name', label: 'Last name', minWidth: 130 },
  { id: 'display_name', label: 'Display Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'gender', label: 'Gender', minWidth: 100 },
  { id: 'role', label: 'Role', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'actions', label: 'Actions', align: 'right' },
];

const constructModalBody = (user?: User | null) =>
  `Are you sure you want to remove user with display name ${user?.display_name} and ID ${user?.id}`;

const UserPage: React.FC = () => {
  const [tableState, setTableState] =
    useState<TableState<User>>(defaultTableState);

  const { data: count, loading: countLoading } = useUsersCountQuery();
  const [fetchUsers, { data, loading, called }] = useUsersLazyQuery({
    variables: {
      limit: tableState.rowsPerPage,
      offset: tableState.rowsPerPage * tableState.page,
    },
  });
  const throttledFetchUsers = useRef(throttle(fetchUsers, 500));

  useEffect(() => {
    fetchUsers({
      variables: constructQueryVariables(tableState),
    });
  }, [fetchUsers]);

  const [deleteUserRequest, { loading: deleteUserByIdLoading }] =
    useDeleteUserByIdMutation({
      onCompleted: () => {
        setTableState({ ...tableState, isRemovingItem: false });
      },
      onError: () => {
        setTableState({ ...tableState, isRemovingItem: false });
      },
      refetchQueries: [
        UsersDocument,
        'users',
        UsersCountDocument,
        'usersCount',
      ],
    });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = tableState.orderBy === property && tableState.order === 'asc';
    const nextOrder = isAsc ? Order_By.Desc : Order_By.Asc;
    setTableState({ ...tableState, order: nextOrder, orderBy: property });
    fetchUsers({
      variables: constructQueryVariables({
        ...tableState,
        order: nextOrder,
        orderBy: property,
      }),
    });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({ ...tableState, searchTerm: event.target.value });
    const term = event.target.value;
    throttledFetchUsers.current({
      variables: constructQueryVariables({ ...tableState, searchTerm: term }),
    });
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableState({ ...tableState, page: newPage });
    fetchUsers({
      variables: constructQueryVariables({ ...tableState, page: newPage }),
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableState({ ...tableState, rowsPerPage: +event.target.value, page: 0 });
    fetchUsers({
      variables: constructQueryVariables({
        ...tableState,
        rowsPerPage: +event.target.value,
        page: 0,
      }),
    });
  };

  const deleteUser = (user: User): void => {
    setTableState({ ...tableState, isRemovingItem: true, removableItem: user });
  };

  const onDeleteApprove = () => {
    deleteUserRequest({ variables: { id: tableState.removableItem!.id } });
  };
  const onDeleteDeny = () =>
    setTableState({ ...tableState, isRemovingItem: false });
  const onDeleteClose = () =>
    setTableState({ ...tableState, isRemovingItem: false });

  return (
    <>
      <Link to="create">
        <Button fullWidth variant="contained" sx={{ marginBottom: 2 }}>
          Create User
        </Button>
      </Link>
      <TextField
        id="search"
        label="Search"
        fullWidth
        value={tableState.searchTerm}
        onChange={onSearchChange}
        sx={{ marginBottom: 2 }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'calc(100vh - 20px)', maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={tableState.orderBy === column.id}
                        direction={
                          tableState.orderBy === column.id
                            ? convertOrder(tableState.order)
                            : 'asc'
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && called
                ? data!.user_aggregate.nodes.map((row: any) => {
                    // Fix this any later
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'actions')
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Link to={`edit/${row.id}`}>
                                  <IconButton aria-label="edit-item">
                                    <Edit />
                                  </IconButton>
                                </Link>
                                <IconButton
                                  aria-label="delete-item"
                                  onClick={() => deleteUser(row)}
                                >
                                  <DeleteOutlined />
                                </IconButton>
                              </TableCell>
                            );

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {countLoading ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count!.user_aggregate.aggregate!.count}
            rowsPerPage={tableState.rowsPerPage}
            page={tableState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <ConfirmationModal
          open={tableState.isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteUserByIdLoading}
          content={{
            body: constructModalBody(tableState.removableItem),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove User',
          }}
        />
      </Paper>
    </>
  );
};

export default UserPage;
