import React from 'react';
import {
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  name: string;
  control: Control<any, object>; // Try to remove this any from here
  label: string;
  labelProps?: Pick<FormControlLabelProps, 'labelPlacement'>;
} & CheckboxProps;

const CheckboxRegistered: React.FC<Props> = ({
  name,
  control,
  defaultValue = false,
  label,
  labelProps,
  ...inputProps
}) => (
  <FormControlLabel
    control={
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(props) => (
          <Checkbox
            checked={props.field.value}
            onChange={(e) => props.field.onChange(e.target.checked)}
            {...inputProps}
          />
        )}
      />
    }
    label={label}
    {...labelProps}
  />
);

export default CheckboxRegistered;
