import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Box,
  Chip,
  SelectProps,
  FormHelperText,
  SxProps,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface IProps {
  name: string;
  control: Control<any, object>;
  options: string[];
  helperText?: string;
  label: string;
  sx?: SxProps;
  readOnly?: boolean;
}

type Props = IProps & SelectProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiSelectRegistered: React.FC<Props> = ({
  name,
  helperText,
  options,
  control,
  label,
  readOnly = false,
  sx,
}: // eslint-disable-next-line no-undef
Props): JSX.Element => {
  return (
    <FormControl sx={sx}>
      <InputLabel id={`${name}-label-id`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            labelId={name}
            id={`${name}-label-id`}
            multiple
            readOnly={readOnly}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: string) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
            {...field}
          >
            {options.map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectRegistered;
