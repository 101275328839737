import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import storage from '../../storage';
import SideNavigationSection from '../SideNavigationSection';
import menuItems from './menuItemsList';
import Link from '../Link';

const SideNavigation: React.FC = () => {
  const navigate = useNavigate();
  const logOut = () => {
    storage.removeAccessToken();
    navigate('/login');
  };

  return (
    <div>
      <Toolbar>
        <Link to="/dashboard">
          <Typography variant="h4" noWrap component="div">
            Cuddlist
          </Typography>
        </Link>
      </Toolbar>
      <Divider />
      <Stack
        direction="column"
        justifyContent="space-between"
        component="div"
        sx={{ height: `calc(100vh - 64px)` }}
      >
        <List>
          {menuItems.map((props) => (
            <SideNavigationSection {...props} />
          ))}
        </List>
        <List>
          <ListItem button key="Logout" onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Stack>
    </div>
  );
};

export default SideNavigation;
