import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordSuccessfullyReset: React.FC = () => {
  const navigate = useNavigate();
  setTimeout(() => navigate('/login'), 3000);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h5">
        Password successfully reset. You will be redirected to login.
      </Typography>
    </Box>
  );
};

export default PasswordSuccessfullyReset;
