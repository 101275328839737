import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const InvalidPasswordResetUrl: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h5">Invalid password reset URL.</Typography>
      <Link to="/requestPasswordReset" style={{ textDecoration: 'none' }}>
        <Typography
          variant="h6"
          sx={{ textDecoration: 'none', color: 'primary.main' }}
        >
          Request another reset
        </Typography>
      </Link>
    </Box>
  );
};

export default InvalidPasswordResetUrl;
