import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export const Link: React.FC<LinkProps> = ({ style, ...props }) => {
  return (
    <RouterLink
      {...props}
      style={{ textDecoration: 'none', color: 'inherit', ...style }}
    />
  );
};

export default Link;
