import React from 'react';
import { SxProps, TextField } from '@mui/material';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface IProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  sx?: SxProps;
  label?: string;
  error?: boolean;
  helperText?: string;
  inputProps?: any;
}

const CurrencyField = <T extends FieldValues>({
  name,
  control,
  sx,
  label,
  ...rest
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <NumberFormat
          value={props.field.value as string}
          onValueChange={(e) => props.field.onChange(e.floatValue)}
          customInput={TextField}
          prefix="$"
          sx={sx}
          label={label}
          {...rest}
        />
      )}
    />
  );
};

export default CurrencyField;
