import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader: React.FC = () => {
  return (
    <CircularProgress
      size={60}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '60px',
        marginLeft: '60px',
      }}
    />
  );
};

export default Loader;
