import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  SxProps,
} from '@mui/material';

interface Props {
  name: string;
  label: string;
  control: Control<any, object>; // Try to remove this any from here
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
}

const RichTextField: React.FC<Props> = ({
  control,
  name,
  error,
  label,
  helperText,
  readOnly = false,
  sx,
}): ReactElement => (
  <FormControl sx={sx} error={error}>
    <InputLabel id={`field-label-${name}`} style={{ marginLeft: '-10px' }}>
      {label}
    </InputLabel>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div style={{ marginTop: '50px' }}>
          <Editor
            toolbar={{
              image: {
                previewImage: true,
                uploadCallback: (file: File) =>
                  new Promise((res) => {
                    res({
                      data: { link: URL.createObjectURL(file) },
                    });
                  }),
                uploadEnabled: true,
              },
            }}
            editorState={value}
            onEditorStateChange={onChange}
            readOnly={readOnly}
          />
        </div>
      )}
    />
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export default RichTextField;
