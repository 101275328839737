import React, { useContext } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../components/Inputs/TextField';
import { useLoginMutation } from '../../api/graphql';
import storage from '../../storage';
import { UserContext } from '../../contexts/user.context';

interface LoginFormData {
  username: string;
  password: string;
}

const schema: yup.SchemaOf<LoginFormData> = yup.object({
  username: yup
    .string()
    .email('Username must be a valid email')
    .required('Username is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginFormData>({
    mode: 'onChange',
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [loginRequest, { loading }] = useLoginMutation({
    onCompleted: ({ logIn }) => {
      storage.setAccessToken(logIn.accessToken);
      setUser(logIn.user);
      navigate('/');
    },
  });
  const login = (formData: LoginFormData) => {
    if (isValid) {
      loginRequest({ variables: formData });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="form"
        onSubmit={handleSubmit(login)}
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <TextField
          control={control}
          name="username"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          autoComplete="username"
          error={!!errors.username}
          helperText={errors.username?.message}
          autoFocus
        />
        <TextField
          control={control}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          error={!!errors.password}
          helperText={errors.password?.message}
          autoComplete="current-password"
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Link to="/requestPasswordReset" style={{ textDecoration: 'none' }}>
            <Typography sx={{ textDecoration: 'none', color: 'primary.main' }}>
              Reset Password
            </Typography>
          </Link>
        </Box>
        <Box component="div" sx={{ mt: 1, position: 'relative' }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
