import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, FormLabel, Typography } from '@mui/material';
import { FieldError, useForm } from 'react-hook-form';
import { EditorState } from 'draft-js';
import { useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import TextField from '../../../../components/Inputs/TextField';
import Loader from '../../../../components/Loader';
import { genderOptions } from '../../../../common/constants';
import {
  PractitionerCountDocument,
  PractitionersDocument,
  useInsertPractitionerMutation,
} from '../../../../api/graphql';
import Checkbox from '../../../../components/Inputs/Checkbox';
import SnackbarManager from '../../../../components/SnackbarManager/manager';
import CurrencyField from '../../../../components/Inputs/Currency';
import MultiSelect from '../../../../components/Inputs/MultiSelect';
import RichTextField from '../../../../components/Inputs/RichTextField';
import SkillsSelect from '../../../../components/SkillsSelect';
import {
  PractitionerInsertForm,
  PractitionerInsertSchema,
  serializeFormData,
} from './form';

const CreatePractitioner: React.FC = () => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PractitionerInsertForm>({
    resolver: yupResolver(PractitionerInsertSchema),
    defaultValues: {
      appointment_type: [],
      skills: [],
      gender: [],
      session_information: EditorState.createEmpty(),
      about: EditorState.createEmpty(),
    },
    mode: 'onChange',
  });
  const [insertPractitionerRequest, { loading }] =
    useInsertPractitionerMutation({
      onError: noop,
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Practitioner successfully added',
          options: { variant: 'success' },
        });
        navigate('/dashboard/practitioners');
      },
      refetchQueries: [
        PractitionersDocument,
        'practitioners',
        PractitionerCountDocument,
        'practitionerCount',
      ],
    });

  if (loading) return <Loader />;

  const insertPractitioner = (data: PractitionerInsertForm) => {
    const serializedData = serializeFormData(data);
    insertPractitionerRequest({
      variables: {
        password: 'ajkalsfdkldasjflkdsaj', // solve this issue
        ...serializedData,
      },
    });
  };

  return (
    <Box component="main">
      <Box component="form" onSubmit={handleSubmit(insertPractitioner)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h4">Insert Practitioner</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Profile</FormLabel>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                name="display_name"
                control={control}
                margin="normal"
                id="display_name"
                label="Display Name"
                error={!!errors.display_name}
                helperText={errors.display_name?.message}
                sx={{ flexGrow: '1' }}
              />
              <TextField
                name="first_name"
                control={control}
                margin="normal"
                id="first_name"
                label="First name"
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
              <TextField
                name="last_name"
                control={control}
                margin="normal"
                id="last_name"
                label="Last Name"
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
              <TextField
                name="slug"
                control={control}
                margin="normal"
                id="slug"
                label="Slug"
                error={!!errors.slug}
                helperText={errors.slug?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
            </Box>
            <MultiSelect
              name="gender"
              label="Gender"
              control={control}
              options={genderOptions}
              error={!!errors.gender}
              helperText={errors.gender?.message}
              sx={{ flexGrow: '1', marginTop: '10px' }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                name="email"
                control={control}
                margin="normal"
                id="email"
                label="Email"
                error={!!errors.email}
                helperText={errors.email?.message}
                sx={{ flexGrow: '1' }}
              />
              <TextField
                name="location"
                control={control}
                margin="normal"
                id="location"
                label="Location"
                error={!!errors.location}
                helperText={errors.location?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
              <TextField
                name="zip_code"
                control={control}
                margin="normal"
                id="zip_code"
                label="ZIP Code"
                error={!!errors.zip_code}
                helperText={errors.zip_code?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
              <TextField
                name="city"
                control={control}
                margin="normal"
                id="city"
                label="City"
                error={!!errors.city}
                helperText={errors.city?.message}
                sx={{ flexGrow: '1', marginLeft: '10px' }}
              />
            </Box>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
              <FormLabel component="legend">Cuddler Info</FormLabel>
              <TextField
                name="short_bio"
                control={control}
                margin="normal"
                id="short_bio"
                label="Short Bio"
                error={!!errors.short_bio}
                helperText={errors.short_bio?.message}
                sx={{ flexGrow: '1' }}
                multiline
              />
              <RichTextField
                name="about"
                label="About"
                error={!!errors.about}
                helperText={(errors.about as FieldError)?.message}
                control={control}
              />
              <RichTextField
                name="session_information"
                label="Session Information"
                error={!!errors.session_information}
                helperText={(errors.session_information as FieldError)?.message}
                control={control}
              />
              <MultiSelect
                name="appointment_type"
                label="Appointment Type"
                control={control}
                options={['In Call', 'Out Call', 'Virtual']}
                error={!!errors.appointment_type}
                helperText={errors.appointment_type?.message}
                sx={{ marginTop: 1 }}
              />
              <TextField
                name="area"
                control={control}
                margin="normal"
                id="area"
                label="Area"
                error={!!errors.area}
                helperText={errors.area?.message}
                sx={{ flexGrow: '1' }}
              />
            </FormControl>
            <SkillsSelect<PractitionerInsertForm>
              id="skills"
              name="skills"
              label="Skills"
              control={control}
              fullWidth
            />
            <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
              <FormLabel component="legend">Rates</FormLabel>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <CurrencyField<PractitionerInsertForm>
                  name="in_call_rate"
                  control={control}
                  sx={{ flexGrow: 1 }}
                  label="In Call Rate"
                  error={!!errors.in_call_rate}
                  helperText={errors.in_call_rate?.message}
                />
                <CurrencyField<PractitionerInsertForm>
                  name="out_call_rate"
                  control={control}
                  sx={{ flexGrow: 1, marginLeft: '10px' }}
                  error={!!errors.out_call_rate}
                  helperText={errors.out_call_rate?.message}
                  label="Out Call Rate"
                />
                <CurrencyField<PractitionerInsertForm>
                  name="virtual_rate"
                  control={control}
                  sx={{ flexGrow: 1, marginLeft: '10px' }}
                  label="Virtual Rate"
                  error={!!errors.virtual_rate}
                  helperText={errors.virtual_rate?.message}
                />
              </Box>
            </FormControl>
            <FormControl sx={{ marginTop: '10px' }}>
              <FormLabel component="legend">Qualifications</FormLabel>
              <Box sx={{ display: 'flex' }}>
                <Checkbox
                  name="is_certified"
                  label="Certified"
                  control={control}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28 },
                  }}
                />
                <Checkbox
                  name="is_trained"
                  label="Trained"
                  control={control}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28 },
                  }}
                />
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ flexGrow: 1, mb: 3 }}
            onClick={handleSubmit(insertPractitioner)}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePractitioner;
