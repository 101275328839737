import React, { ReactElement } from 'react';
import { To, useMatch, useResolvedPath } from 'react-router-dom';
import Link from '../Link';

// eslint-disable-next-line no-unused-vars
export type ActiveLinkChildren = (isMatch: boolean) => ReactElement;

interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  children: ActiveLinkChildren;
  reloadDocument?: boolean;
  exact?: boolean;
  replace?: boolean;
  state?: any;
  to: To;
}

const ActiveLink: React.FC<LinkProps> = ({
  children,
  to,
  exact = false,
  ...props
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({
    path: resolved.pathname,
    caseSensitive: false,
    end: exact,
  });

  return (
    <div>
      <Link to={to} {...props}>
        {children(!!match)}
      </Link>
    </div>
  );
};

export default ActiveLink;
