import format from 'date-fns/format';
import { Order_By } from '../api/graphql';
import { AppSelectOption } from '../components/Inputs/SelectField';
import { Column } from './types';

export const commonColumns: readonly Column[] = [
  { id: 'id', label: 'ID', minWidth: 60 },
  {
    id: 'created_at',
    label: 'Created At',
    format: (date: string) => format(Date.parse(date), 'MM-dd-yyyy'),
    minWidth: 130,
  },
  {
    id: 'updated_at',
    label: 'Updated At',
    format: (date: string) => format(Date.parse(date), 'MM-dd-yyyy'),
    minWidth: 130,
  },
];

export const defaultTableState = {
  page: 0,
  rowsPerPage: 25,
  isRemovingItem: false,
  removableItem: null,
  orderBy: null,
  order: Order_By.Asc,
  searchTerm: '',
};

export const listedStatusOptions: AppSelectOption[] = [
  {
    value: 'Verified',
    label: 'Listed',
  },
  {
    value: 'Unverified',
    label: 'Unlisted',
  },
];

export const verificationStatusOptions: AppSelectOption[] = [
  {
    value: 'Verified',
    label: 'Verified',
  },
  {
    value: 'Unverified',
    label: 'Unverified',
  },
];

export const genderOptions: string[] = [
  'Male',
  'Female',
  'Nonbinary',
  'Genderqueer',
  'Agender',
  'Prefer not to say',
];

export const activeStatusOptions: AppSelectOption[] = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];
