import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import client from '../../api/client';
import theme from '../../common/theme';
import Routing from '../Routing';
import SnackbarConfiguration from '../SnackbarConfiguration';
import PermissionProvider from '../../contexts/permissions.context';
import UserContextProvider from '../../contexts/user.context';

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <UserContextProvider>
            <PermissionProvider>
              <SnackbarConfiguration>
                <CssBaseline />
                <BrowserRouter>
                  <Routing />
                </BrowserRouter>
              </SnackbarConfiguration>
            </PermissionProvider>
          </UserContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
