import { useContext } from 'react';
import { AuthenticatedUser, UserContext } from '../contexts/user.context';
import storage from '../storage';

interface AuthenticatedActions {
  user: AuthenticatedUser | null;
  isAuthenticated: boolean | null;
  isAuthenticating: boolean;
  logOut: () => void;
}

const useAuthenticated = (): AuthenticatedActions => {
  const { user, setUser, isAuthenticating } = useContext(UserContext);
  const logOut = () => {
    storage.removeAuthTokens();
    setUser(null);
  };

  return {
    user: user ?? null,
    isAuthenticated: user ? true : null,
    logOut,
    isAuthenticating,
  };
};

export default useAuthenticated;
