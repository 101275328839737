import React from 'react';
import { SnackbarProvider } from 'notistack';
import SnackbarManager from '../SnackbarManager';

const SnackbarConfiguration: React.FC = ({ children }) => (
  <SnackbarProvider
    maxSnack={1}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    {children}
    <SnackbarManager />
  </SnackbarProvider>
);

export default SnackbarConfiguration;
