import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableSortLabel,
  TextField,
  TableCell,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import {
  Enrollment,
  Order_By,
  useEnrollmentCountQuery,
  useEnrollmentsLazyQuery,
} from '../../../api/graphql';
import { commonColumns, defaultTableState } from '../../../common/constants';
import { Column, TableState } from '../../../common/types';
import { convertOrder } from '../../../common/utils';
import { constructQueryVariables } from './utils';

const columns: readonly Column[] = [
  ...commonColumns,
  { id: 'city', label: 'City' },
  { id: 'country', label: 'Country' },
  { id: 'email', label: 'Email' },
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'primary_phone_number', label: 'Primary Phone Number' },
];

export const EnrollmentsPage: React.FC = () => {
  const [tableState, setTableState] =
    useState<TableState<Enrollment>>(defaultTableState);

  const { data: count, loading: countLoading } = useEnrollmentCountQuery();
  const [fetchEnrollments, { data, loading, called }] =
    useEnrollmentsLazyQuery();
  const throttledFetchEnrollments = useRef(throttle(fetchEnrollments, 500));

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({ ...tableState, searchTerm: event.target.value });
    const term = event.target.value;
    throttledFetchEnrollments.current({
      variables: constructQueryVariables({ ...tableState, searchTerm: term }),
    });
  };

  useEffect(() => {
    fetchEnrollments({
      variables: constructQueryVariables(tableState),
    });
  }, [fetchEnrollments, tableState]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = tableState.orderBy === property && tableState.order === 'asc';
    const nextOrder = isAsc ? Order_By.Desc : Order_By.Asc;
    setTableState({ ...tableState, order: nextOrder, orderBy: property });
    fetchEnrollments({
      variables: constructQueryVariables({
        ...tableState,
        order: nextOrder,
        orderBy: property,
      }),
    });
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableState({ ...tableState, page: newPage });
    fetchEnrollments({
      variables: constructQueryVariables({ ...tableState, page: newPage }),
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableState({ ...tableState, rowsPerPage: +event.target.value, page: 0 });
    fetchEnrollments({
      variables: constructQueryVariables({
        ...tableState,
        rowsPerPage: +event.target.value,
        page: 0,
      }),
    });
  };

  return (
    <>
      <TextField
        id="search"
        label="Search"
        fullWidth
        value={tableState.searchTerm}
        onChange={onSearchChange}
        sx={{ marginBottom: 2 }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'calc(100vh - 20px)', maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={tableState.orderBy === column.id}
                        direction={
                          tableState.orderBy === column.id
                            ? convertOrder(tableState.order)
                            : 'asc'
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.enrollment_aggregate &&
              data?.enrollment_aggregate.nodes.length === 0
                ? 'No data found'
                : null}
              {!loading && called
                ? data!.enrollment_aggregate.nodes.map((row: any) => {
                    // Fix this any later
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = column.valuePath
                            ? get(row, column.valuePath)
                            : row[column.id];
                          const formattedValue = column.format
                            ? column.format(value)
                            : value;
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.children
                                ? column.children(formattedValue)
                                : formattedValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {countLoading ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count!.enrollment_aggregate.aggregate!.count}
            rowsPerPage={tableState.rowsPerPage}
            page={tableState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
};

export default EnrollmentsPage;
