import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Loader from '../../../components/Loader';
import { useCreateUserMutation } from '../../../api/graphql';
import { Nullable } from '../../../common/types';
import {
  AppSelect,
  AppSelectOption,
} from '../../../components/Inputs/SelectField';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { genderOptions } from '../../../common/constants';
import TextField from '../../../components/Inputs/TextField';
import MultiSelect from '../../../components/Inputs/MultiSelect';

interface UserCreateForm {
  role: string;
  display_name?: string;
  email: string;
  first_name?: Nullable<string>;
  gender: any;
  last_name?: Nullable<string>;
}

const UserCreateSchema: yup.SchemaOf<UserCreateForm> = yup.object({
  role: yup
    .string()
    .oneOf(['blogger', 'editor', 'admin'])
    .required()
    .label('Role'),
  gender: yup
    .array()
    .of(
      yup
        .string()
        .oneOf([
          'Male',
          'Female',
          'Nonbinary',
          'Genderqueer',
          'Agender',
          'Prefer not to say',
        ])
    )
    .nullable()
    .label('Gender'),
  display_name: yup.string().label('Display Name'),
  email: yup.string().email().required().label('Email'),
  first_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .label('First Name'),
  last_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .label('Last Name'),
});

const roleOptions: AppSelectOption[] = [
  {
    value: 'blogger',
    label: 'Blogger',
  },
  {
    value: 'editor',
    label: 'Editor',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'bloggerPractitioner',
    label: 'Blogger/Practitioner',
  },
  {
    value: 'editorPractitioner',
    label: 'Editor/Practitioner',
  },
  {
    value: 'admin',
    label: 'Admin/Practitioner',
  },
];

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm<UserCreateForm>({
    resolver: yupResolver(UserCreateSchema),
    mode: 'onChange',
    defaultValues: { gender: [] },
  });
  const [createUserRequest, { loading, error }] = useCreateUserMutation({
    onCompleted: () => {
      SnackbarManager.addSnackbar({
        message: 'User successfully created',
        options: { variant: 'success' },
      });
      navigate('/dashboard/users');
    },
  });

  const createUser = (formData: UserCreateForm) => {
    createUserRequest({
      variables: {
        ...formData,
        password: 'ajkalsfdkldasjflkdsaj',
      },
    });
  };

  return (
    <Box component="main">
      <Box component="form" onSubmit={handleSubmit(createUser)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <AppSelect<UserCreateForm, typeof roleOptions>
            name="role"
            label="Role"
            control={control}
            options={roleOptions}
            error={!!errors.role}
            helperText={errors.role?.message}
            sx={{ flexGrow: 1 }}
          />
          <MultiSelect
            name="gender"
            label="Gender"
            control={control}
            options={genderOptions}
            error={!!errors.gender}
            helperText={errors.gender?.message}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="display_name"
            control={control}
            margin="normal"
            id="display_name"
            label="Display Name"
            error={!!errors.display_name}
            helperText={errors.display_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="first_name"
            control={control}
            margin="normal"
            id="first_name"
            label="First Name"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="last_name"
            control={control}
            margin="normal"
            id="last_name"
            label="Last Name"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <TextField
          name="email"
          control={control}
          margin="normal"
          id="email"
          label="Email"
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ flexGrow: '1' }}
        />
        {error ? <Typography>{error.message}</Typography> : null}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isDirty || !isValid}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
          {loading ? (
            <Backdrop open>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateUser;
