export const USER_BY_EMAIL_NOT_FOUND_ERROR_CODE = 'user-by-email-not-found';
export const INVALID_JWT_ERROR_CODE = 'invalid-jwt';
export const INVALID_CREDENTIALS_ERROR_CODE = 'invalid-credentials';
export const DATA_EXCEPTION_ERROR_CODE = 'data-exception';
export const FAILED_VALIDATION_ERROR_CODE = 'validation-failed';
export const PASSWORD_RESET_INVALID_TOKEN_CODE = 'missing-reset-token';
export const CONSTRAINT_VIOLATION_CODE = 'constraint-violation';

export const MALFORMED_REQUEST_ERROR_MESSAGE = 'Malformed request';
export const INVALID_CREDENTIALS_ERROR_MESSAGE = 'Invalid credentials';
export const USER_BY_EMAIL_NOT_FOUND_ERROR_MESSAGE =
  'User by this email is not found';
export const INVALID_PASSWORD_RESET_TOKEN_ERROR_MESSAGE =
  'Password reset period has ended. Please, initiate another one';
export const NON_UNIQUE_EMAIL_ERROR_MESSAGE =
  'User with this email already exists';
export const NON_UNIQUE_SLUG_ERROR_MESSAGE = 'This slug already exists';

export const UNIQUE_EMAIL_INDEX_ERROR_MESSAGE =
  'Uniqueness violation. duplicate key value violates unique constraint "IDX_87102a93784486b32f39aefc93"';
export const UNIQUE_SLUG_INDEX_VIOLATION_MESSAGE =
  'Uniqueness violation. duplicate key value violates unique constraint "IDX_752547c919670ee67b784f5465"';
