import React, { FunctionComponent } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface IProps<K extends FieldValues> {
  name: Path<K>;
  control: Control<K, object>; // Try to remove this any from her
}

type Props<
  T,
  B extends FunctionComponent<any>,
  K extends FieldValues
> = IProps<K> & AutocompleteProps<T, true, false, false, B>;

const AutocompleteRegistered = <
  T extends unknown,
  B extends FunctionComponent<any>,
  K extends FieldValues
>({
  control,
  name,
  ...inputProps
}: // eslint-disable-next-line no-undef
Props<T, B, K>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <Autocomplete
          onChange={(event, item) => {
            onChange(item);
          }}
          value={value}
          onBlur={onBlur}
          {...inputProps}
        />
      )}
    />
  );
};

export default AutocompleteRegistered;
