import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from '../../pages/login';
import DashboardIndexPage from '../../pages/dashboard';
import Layout from '../Layout';
import RequestPasswordResetPage from '../../pages/requestPasswordReset';
import ResetPasswordPage from '../../pages/requestPassword';
import UserPage from '../../pages/dashboard/user';
import EditUser from '../../pages/dashboard/user/edit';
import BlogPage from '../../pages/dashboard/blog';
import EditBlog from '../../pages/dashboard/blog/edit';
import CreateBlog from '../../pages/dashboard/blog/create';
import SkillsPage from '../../pages/dashboard/skills';
import CreateSkill from '../../pages/dashboard/skills/create';
import EditSkill from '../../pages/dashboard/skills/edit';
import ClientsPage from '../../pages/dashboard/clients';
import EditClient from '../../pages/dashboard/clients/edit';
import PressPage from '../../pages/dashboard/press';
import EditPress from '../../pages/dashboard/press/edit';
import CreatePress from '../../pages/dashboard/press/create';
import PractitionerPage from '../../pages/dashboard/practitioner';
import EditPractitioner from '../../pages/dashboard/practitioner/edit/edit';
import ReviewPractitioner from '../../pages/dashboard/practitioner/review';
import AnnouncementsPage from '../../pages/dashboard/announcements';
import EditAnnouncement from '../../pages/dashboard/announcements/edit';
import CreateAnnouncement from '../../pages/dashboard/announcements/create';
import CreateClient from '../../pages/dashboard/clients/create';
import CreatePractitioner from '../../pages/dashboard/practitioner/create/create';
import CreateUser from '../../pages/dashboard/user/create';
import EnrollmentsPage from '../../pages/dashboard/enrollments';
import AuthenticatedView from '../authenticatedView';

const Routing: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/dashboard"
        element={
          <AuthenticatedView
            roles={[
              'admin',
              'blogger',
              'editor',
              'bloggerPractitioner',
              'editorPractitioner',
            ]}
          >
            <Layout>
              <Outlet />
            </Layout>
          </AuthenticatedView>
        }
      >
        <Route index element={<DashboardIndexPage />} />
        <Route path="users">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <UserPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreateUser />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView roles={['admin']}>
                <EditUser />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="blogs">
          <Route
            index
            element={
              <AuthenticatedView
                roles={['admin', 'blogger', 'bloggerPractitioner']}
              >
                <BlogPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView
                roles={['admin', 'blogger', 'bloggerPractitioner']}
              >
                <EditBlog />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView
                roles={['admin', 'blogger', 'bloggerPractitioner']}
              >
                <CreateBlog />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="skills">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <SkillsPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreateSkill />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView roles={['admin']}>
                <EditSkill />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="clients">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <ClientsPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView roles={['admin']}>
                <EditClient />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreateClient />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="press">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <PressPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView roles={['admin']}>
                <EditPress />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreatePress />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="practitioners">
          <Route
            index
            element={
              <AuthenticatedView
                roles={['admin', 'editor', 'editorPractitioner']}
              >
                <PractitionerPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView
                roles={['admin', 'editor', 'editorPractitioner']}
              >
                <EditPractitioner />
              </AuthenticatedView>
            }
          />
          <Route
            path="review/:id"
            element={
              <AuthenticatedView
                roles={['admin', 'editor', 'editorPractitioner']}
              >
                <ReviewPractitioner />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreatePractitioner />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="announcements">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <AnnouncementsPage />
              </AuthenticatedView>
            }
          />
          <Route
            path="edit/:id"
            element={
              <AuthenticatedView roles={['admin']}>
                <EditAnnouncement />
              </AuthenticatedView>
            }
          />
          <Route
            path="create"
            element={
              <AuthenticatedView roles={['admin']}>
                <CreateAnnouncement />
              </AuthenticatedView>
            }
          />
        </Route>
        <Route path="enrollments">
          <Route
            index
            element={
              <AuthenticatedView roles={['admin']}>
                <EnrollmentsPage />
              </AuthenticatedView>
            }
          />
        </Route>
      </Route>
      <Route
        path="/requestPasswordReset"
        element={<RequestPasswordResetPage />}
      />
      <Route path="/resetPassword" element={<ResetPasswordPage />} />
    </Routes>
  );
};

export default Routing;
