import { GraphQLErrors } from '@apollo/client/errors';
import SnackbarManager from '../../components/SnackbarManager/manager';
import storage from '../../storage';
import {
  CONSTRAINT_VIOLATION_CODE,
  DATA_EXCEPTION_ERROR_CODE,
  FAILED_VALIDATION_ERROR_CODE,
  INVALID_CREDENTIALS_ERROR_CODE,
  INVALID_CREDENTIALS_ERROR_MESSAGE,
  INVALID_JWT_ERROR_CODE,
  INVALID_PASSWORD_RESET_TOKEN_ERROR_MESSAGE,
  MALFORMED_REQUEST_ERROR_MESSAGE,
  PASSWORD_RESET_INVALID_TOKEN_CODE,
  USER_BY_EMAIL_NOT_FOUND_ERROR_CODE,
  USER_BY_EMAIL_NOT_FOUND_ERROR_MESSAGE,
  UNIQUE_EMAIL_INDEX_ERROR_MESSAGE,
  NON_UNIQUE_EMAIL_ERROR_MESSAGE,
  UNIQUE_SLUG_INDEX_VIOLATION_MESSAGE,
  NON_UNIQUE_SLUG_ERROR_MESSAGE,
} from './constants';

export const handleGraphqlErrors = (graphQLErrors: GraphQLErrors) =>
  graphQLErrors.forEach(({ extensions, message }) => {
    switch (extensions.code) {
      case DATA_EXCEPTION_ERROR_CODE:
      case FAILED_VALIDATION_ERROR_CODE:
        SnackbarManager.addSnackbar({
          message: MALFORMED_REQUEST_ERROR_MESSAGE,
          options: {
            variant: 'error',
          },
        });
        break;
      case INVALID_CREDENTIALS_ERROR_CODE:
        SnackbarManager.addSnackbar({
          message: INVALID_CREDENTIALS_ERROR_MESSAGE,
          options: {
            variant: 'error',
          },
        });
        break;
      case INVALID_JWT_ERROR_CODE:
        storage.removeAccessToken();
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', '/login');
        break;
      case USER_BY_EMAIL_NOT_FOUND_ERROR_CODE:
        SnackbarManager.addSnackbar({
          message: USER_BY_EMAIL_NOT_FOUND_ERROR_MESSAGE,
          options: {
            variant: 'error',
          },
        });
        break;
      case CONSTRAINT_VIOLATION_CODE:
        switch (message) {
          case UNIQUE_EMAIL_INDEX_ERROR_MESSAGE:
            SnackbarManager.addSnackbar({
              message: NON_UNIQUE_EMAIL_ERROR_MESSAGE,
              options: {
                variant: 'error',
              },
            });
            break;
          case UNIQUE_SLUG_INDEX_VIOLATION_MESSAGE:
            SnackbarManager.addSnackbar({
              message: NON_UNIQUE_SLUG_ERROR_MESSAGE,
              options: {
                variant: 'error',
              },
            });
            break;
          default:
            break;
        }
        break;
      case PASSWORD_RESET_INVALID_TOKEN_CODE:
        SnackbarManager.addSnackbar({
          message: INVALID_PASSWORD_RESET_TOKEN_ERROR_MESSAGE,
          options: {
            variant: 'error',
          },
        });
        break;
      default:
        break;
    }
  });
