import React, { useEffect, useState } from 'react';
import { AutocompleteProps, Checkbox, TextField } from '@mui/material';
import { Control, FieldValues, Path } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SkillsQuery, useSkillsLazyQuery } from '../../api/graphql';
import Autocomplete from '../Inputs/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps<K extends FieldValues> {
  id: string;
  name: Path<K>;
  control: Control<K, object>;
  label: string;
}

type Props<K extends FieldValues> = IProps<K> &
  Partial<
    AutocompleteProps<
      SkillsQuery['skill_aggregate']['nodes'][0],
      true,
      false,
      false
    >
  >;

const SkillsSelect = <K extends FieldValues>({
  id,
  name,
  label,
  control,
  ...inputProps
}: Props<K>) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<
    SkillsQuery['skill_aggregate']['nodes']
  >([]);
  const [fetchSkills, { loading }] = useSkillsLazyQuery({
    onCompleted: (data) => {
      setOptions(data.skill_aggregate.nodes);
    },
  });

  useEffect(() => {
    if (open) {
      fetchSkills();
    }
  }, [open, fetchSkills]);

  return (
    <Autocomplete
      multiple
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      disableCloseOnSelect
      loading={loading}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      control={control}
      name={name}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Favorites" />
      )}
      {...inputProps}
    />
  );
};

export default SkillsSelect;
