import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import SnackbarManager from '../../components/SnackbarManager/manager';
import environment from '../../environment';
import storage from '../../storage';
import { handleGraphqlErrors } from './utils';

const TerminatingLink = new HttpLink({
  uri: environment.apiEndpoint,
});

const AuthenticationLink = new ApolloLink((operation, forward) => {
  const authHeaders = storage.accessToken
    ? { authorization: `Bearer ${storage.accessToken}` }
    : {};
  operation.setContext(({ headers }: { headers: Record<string, any> }) => ({
    headers: {
      ...authHeaders,
      ...headers,
    },
  }));

  return forward(operation);
});

const ErrorHandlerLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) handleGraphqlErrors(graphQLErrors);

  if (networkError)
    SnackbarManager.addSnackbar({
      message: 'Network Error',
      options: {
        variant: 'error',
      },
    });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([ErrorHandlerLink, AuthenticationLink, TerminatingLink]),
});

export default client;
