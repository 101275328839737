import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useInitiateResetPasswordMutation } from '../../api/graphql';
import TextField from '../../components/Inputs/TextField';

interface PasswordResetRequestForm {
  email: string;
}

const schema: yup.SchemaOf<PasswordResetRequestForm> = yup.object({
  email: yup
    .string()
    .email('Provided email is not valid')
    .required('Email is required'),
});

const RequestPasswordResetPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<PasswordResetRequestForm>({
    mode: 'onChange',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const [initiatePasswordResetRequest, { loading, data }] =
    useInitiateResetPasswordMutation();
  const initiatePasswordReset = ({ email }: PasswordResetRequestForm) => {
    if (isValid) {
      initiatePasswordResetRequest({
        variables: { email },
      });
    }
  };

  if (data) {
    return (
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h4">Password reset email is sent</Typography>
      </Box>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      onSubmit={handleSubmit(initiatePasswordReset)}
    >
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Enter email for resetting password
        </Typography>
        <TextField
          control={control}
          name="email"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message}
          autoFocus
        />

        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography sx={{ textDecoration: 'none', color: 'primary.main' }}>
              Back to Login
            </Typography>
          </Link>
        </Box>
        <Box component="form" noValidate sx={{ mt: 1, position: 'relative' }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 2, mb: 2 }}
          >
            Send Email
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default RequestPasswordResetPage;
