import { EventEmitter } from 'eventemitter3';
import { OptionsObject, SnackbarMessage } from 'notistack';

const snackbarEmitter = new EventEmitter();

export const SNACKBAR_ADDED = 'SNACKBAR_ADDED';

interface SnackbarPayload {
  message: SnackbarMessage;
  options?: OptionsObject;
}

// eslint-disable-next-line no-unused-vars
type AddSnackbarPayloadFunction = (payload: SnackbarPayload) => void;

const SnackbarManager = {
  onSnackbar: (fn: AddSnackbarPayloadFunction) =>
    snackbarEmitter.on(SNACKBAR_ADDED, fn),
  cleanUp: (fn?: () => void) => snackbarEmitter.off(SNACKBAR_ADDED, fn),
  addSnackbar: (payload: SnackbarPayload) =>
    snackbarEmitter.emit(SNACKBAR_ADDED, payload),
};

Object.freeze(SnackbarManager);

export default SnackbarManager;
