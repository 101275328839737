import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface ConfirmationModalContent {
  title: string;
  body: string;
  agreeText: string;
  denyText: string;
}

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onApprove: React.MouseEventHandler<HTMLButtonElement>;
  onDeny: React.MouseEventHandler<HTMLButtonElement>;
  content: ConfirmationModalContent;
  showLoader?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onApprove,
  onDeny,
  content,
  showLoader = false,
}) => {
  const { agreeText, body, denyText, title } = content;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ position: 'relative' }}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ display: 'relative' }}
          >
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeny}>{denyText}</Button>
          <Button onClick={onApprove} autoFocus>
            {agreeText}
          </Button>
        </DialogActions>
        {showLoader && (
          <CircularProgress
            size={60}
            sx={{
              position: 'absolute',
              top: '35%',
              left: '45%',
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
