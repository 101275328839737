import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import {
  useDeleteUserByIdMutation,
  UsersCountDocument,
  UsersDocument,
  useUpdateUserByIdMutation,
  useUserQuery,
} from '../../../api/graphql';
import { Nullable } from '../../../common/types';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import {
  AppSelect,
  AppSelectOption,
} from '../../../components/Inputs/SelectField';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import {
  genderOptions,
  verificationStatusOptions,
} from '../../../common/constants';
import MultiSelect from '../../../components/Inputs/MultiSelect';

interface UserEditForm {
  id: number;
  role: string;
  status: string;
  display_name?: string;
  email: string;
  first_name?: Nullable<string>;
  gender: any;
  last_name?: Nullable<string>;
}

const UserEditSchema: yup.SchemaOf<UserEditForm> = yup.object({
  id: yup.number().integer().required(),
  role: yup
    .string()
    .oneOf(['blogger', 'practitioner', 'editor', 'client', 'admin'])
    .required()
    .label('Role'),
  status: yup
    .string()
    .oneOf(['Verified', 'Unverified'])
    .required()
    .label('Status'),
  gender: yup
    .array()
    .of(
      yup
        .string()
        .oneOf([
          'Male',
          'Female',
          'Nonbinary',
          'Genderqueer',
          'Agender',
          'Prefer not to say',
        ])
    )
    .nullable()
    .label('Gender'),
  display_name: yup.string().label('Display Name'),
  email: yup.string().email().required().label('Email'),
  first_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .label('First Name'),
  last_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .label('Last Name'),
});

const roleOptions: AppSelectOption[] = [
  {
    value: 'blogger',
    label: 'Blogger',
  },
  {
    value: 'practitioner',
    label: 'Practitioner',
  },
  {
    value: 'editor',
    label: 'Editor',
  },
  {
    value: 'client',
    label: 'Client',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'bloggerPractitioner',
    label: 'Blogger/Practitioner',
  },
  {
    value: 'editorPractitioner',
    label: 'Editor/Practitioner',
  },
  {
    value: 'admin',
    label: 'Admin/Practitioner',
  },
];

const constructModalBody = (user?: any) =>
  `Are you sure you want to remove user with display name ${user?.display_name} and ID ${user?.id}`;

const EditUser: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: user, loading } = useUserQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    getValues,
  } = useForm<UserEditForm>({
    resolver: yupResolver(UserEditSchema),
    defaultValues: { gender: [] },
    mode: 'onChange',
  });
  const [updateUserByIdRequest, { loading: updateLoading }] =
    useUpdateUserByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'User successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [deleteUserRequest, { loading: deleteUserByIdLoading }] =
    useDeleteUserByIdMutation({
      onCompleted: () => {
        setIsRemovingItem(false);
        SnackbarManager.addSnackbar({
          message: `User with ID ${user?.user_by_pk?.id} successfully deleted`,
          options: { variant: 'success' },
        });
        navigate('/dashboard/users');
      },
      onError: () => {
        setIsRemovingItem(false);
      },
      refetchQueries: [
        UsersDocument,
        'users',
        UsersCountDocument,
        'usersCount',
      ],
    });

  useEffect(() => {
    if (user) {
      const { user_by_pk } = user;
      const parsed = {
        ...user_by_pk,
        created_at: format(parseISO(user_by_pk?.created_at), 'MM-dd-yyyy'),
        updated_at: format(parseISO(user_by_pk?.updated_at), 'MM-dd-yyyy'),
      };
      reset(parsed);
    }
  }, [user?.user_by_pk, loading, reset]);

  const updateUser = (formData: UserEditForm) => {
    updateUserByIdRequest({
      variables: formData,
    });
  };

  const promptUserDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deleteUserRequest({ variables: { id: user!.user_by_pk!.id } });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <Box component="form" onSubmit={handleSubmit(updateUser)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <AppSelect<UserEditForm, typeof roleOptions>
            name="role"
            label="Role"
            control={control}
            options={roleOptions}
            error={!!errors.role}
            helperText={errors.role?.message}
            sx={{ flexGrow: 1 }}
          />
          <AppSelect<UserEditForm, typeof verificationStatusOptions>
            name="status"
            label="Status"
            control={control}
            options={verificationStatusOptions}
            error={!!errors.status}
            helperText={errors.status?.message}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          />
          <MultiSelect
            name="gender"
            label="Gender"
            control={control}
            options={genderOptions}
            error={!!errors.gender}
            helperText={errors.gender?.message}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="display_name"
            control={control}
            margin="normal"
            id="display_name"
            label="Display Name"
            error={!!errors.display_name}
            helperText={errors.display_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="first_name"
            control={control}
            margin="normal"
            id="first_name"
            label="First Name"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="last_name"
            control={control}
            margin="normal"
            id="last_name"
            label="Last Name"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <TextField
          name="email"
          control={control}
          margin="normal"
          id="email"
          label="Email"
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ flexGrow: '1' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isDirty || !isValid}
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptUserDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteUserByIdLoading}
          content={{
            body: constructModalBody(user!.user_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove User',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditUser;
