import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import TextField from '../../components/Inputs/TextField';
import {
  INVALID_PASSWORD_ERROR_MESSAGE,
  PASSWORD_CONFIRMATION_ERROR_MESSAGE,
  PASSWORD_REGEX,
} from './constants';
import { useResetPasswordMutation } from '../../api/graphql';
import PasswordSuccessfullyReset from '../../components/PasswordSuccessfullyReset';
import InvalidPasswordResetUrl from '../../components/InvalidPasswordResetUrl';

interface PasswordResetForm {
  password: string;
  passwordConfirmation: string | undefined; // For yup reference (ref) typings
}

const passwordConfirmationFormSchema: yup.SchemaOf<PasswordResetForm> =
  yup.object({
    password: yup
      .string()
      .required()
      .matches(PASSWORD_REGEX, {
        message: INVALID_PASSWORD_ERROR_MESSAGE,
      })
      .label('Password'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], PASSWORD_CONFIRMATION_ERROR_MESSAGE)
      .required()
      .label('Password'),
  });

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [requestPasswordMutation, { loading, data }] =
    useResetPasswordMutation();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PasswordResetForm>({
    mode: 'onBlur',
    defaultValues: { password: '', passwordConfirmation: '' },
    resolver: yupResolver(passwordConfirmationFormSchema),
  });
  const token = searchParams.get('token');
  const userId = searchParams.get('uid')
    ? parseInt(searchParams.get('uid')!, 10)
    : false;

  if (data) return <PasswordSuccessfullyReset />;

  if (!token || !userId) return <InvalidPasswordResetUrl />;

  const resetPassword = ({ password }: PasswordResetForm) => {
    if (isValid) {
      requestPasswordMutation({
        variables: {
          resetPasswordInput: {
            password,
            token,
            userId,
          },
        },
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      onSubmit={handleSubmit(resetPassword)}
    >
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component="form"
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <TextField
          id="password"
          name="password"
          type="password"
          label="New Password"
          autoComplete="password"
          error={!!errors.password}
          helperText={errors.password?.message}
          control={control}
          margin="normal"
          required
          fullWidth
          autoFocus
        />
        <TextField
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Confirm Password"
          type="password"
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
          autoComplete="current-password-confirmation"
          control={control}
          margin="normal"
          required
          fullWidth
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 2, mb: 2 }}
        >
          Reset Password
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography sx={{ textDecoration: 'none', color: 'primary.main' }}>
              Login
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
