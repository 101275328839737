import React, { memo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  FormHelperText,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  Control,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form/dist/types';

export const getAppSelectOptions = <LabelType, ValueType>(
  labels: readonly LabelType[],
  values: readonly ValueType[]
) =>
  values.map((value, index) => ({
    label: labels[index],
    value,
  }));

export interface AppSelectOption {
  label: string;
  value: string;
}

interface AppSelectProps<
  FormFieldTypes,
  AppSelectOptions extends AppSelectOption[]
> {
  name: Path<FormFieldTypes>;
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormFieldTypes, object>;
  options: AppSelectOptions;
  defaultValue?: AppSelectOptions[number]['value'] | '';
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export const UnmemoizedAppSelect = <
  FormFieldTypes,
  AppSelectOptions extends AppSelectOption[]
>({
  name,
  label,
  control,
  options,
  defaultValue = '',
  error,
  helperText,
  disabled,
  readOnly = false,
  sx,
}: // eslint-disable-next-line no-undef
AppSelectProps<FormFieldTypes, AppSelectOptions>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={
        defaultValue as UnpackNestedValue<
          PathValue<FormFieldTypes, Path<FormFieldTypes>>
        >
      }
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl sx={sx} error={error} disabled={disabled}>
          <InputLabel id={`field-label-${name}`}>{label}</InputLabel>
          <Select
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            label={label}
            aria-labelledby={`field-label-${name}`}
            readOnly={readOnly}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const AppSelect = memo(
  UnmemoizedAppSelect
) as typeof UnmemoizedAppSelect & { displayName: string };
AppSelect.displayName = 'AppSelect';
