/* eslint-disable class-methods-use-this */
import { ACCESS_TOKEN } from './keys';

class Storage {
  setAccessToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  }

  removeAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  public removeAuthTokens() {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN) ?? '';
  }
}

export default new Storage();
