import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactElement } from 'react';
import { To } from 'react-router-dom';
import ActiveLink from '../ActiveLink';
import Restricted from '../restricted';

export interface MenuItem {
  key: number;
  label: string;
  icon: ReactElement;
  path: To;
  restrictedTo: string[];
}

const SideNavigationSection: React.FC<MenuItem> = ({
  path,
  icon,
  label,
  restrictedTo,
}) => {
  return restrictedTo ? (
    <Restricted to={restrictedTo}>
      <ActiveLink to={path}>
        {(isActive) => (
          <ListItemButton key={label} selected={isActive}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        )}
      </ActiveLink>
    </Restricted>
  ) : (
    <ActiveLink to={path}>
      {(isActive) => (
        <ListItemButton key={label} selected={isActive}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      )}
    </ActiveLink>
  );
};

export default SideNavigationSection;
