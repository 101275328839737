import { Box, CircularProgress } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useAuthenticated from '../../hooks/useAuthenticated';
import usePermission from '../../hooks/usePermissions';

interface AuthProps {
  roles: string[];
}

const AuthenticatedView: React.FC<PropsWithChildren<AuthProps>> = (props) => {
  const { children, roles } = props;
  const { isAuthenticating } = useAuthenticated();
  const isAllowed = usePermission(roles);

  if (isAuthenticating) {
    return <CircularProgress />;
  }

  if (!isAllowed) {
    return <Navigate to="/login" />;
  }

  return <Box>{children}</Box>;
};

export default AuthenticatedView;
