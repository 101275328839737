import { TableState } from '../../../common/types';

export const constructQueryVariables = <E>(tableState: TableState<E>) => {
  const searchConditions =
    tableState.searchTerm.length > 0
      ? [
          {
            _or: [
              { first_name: { _ilike: `%${tableState.searchTerm}%` } },
              {
                last_name: { _ilike: `%${tableState.searchTerm}%` },
              },
              {
                display_name: { _ilike: `%${tableState.searchTerm}%` },
              },
              {
                email: { _ilike: `%${tableState.searchTerm}%` },
              },
            ],
          },
        ]
      : [];
  return {
    limit: tableState.rowsPerPage,
    offset: tableState.rowsPerPage * tableState.page,
    display_name_order:
      tableState.orderBy === 'display_name' ? tableState.order : undefined,
    email_order: tableState.orderBy === 'email' ? tableState.order : undefined,
    title_order: tableState.orderBy === 'title' ? tableState.order : undefined,
    first_name_order:
      tableState.orderBy === 'first_name' ? tableState.order : undefined,
    gender_order:
      tableState.orderBy === 'gender' ? tableState.order : undefined,
    last_name_order:
      tableState.orderBy === 'last_name' ? tableState.order : undefined,
    role_order: tableState.orderBy === 'role' ? tableState.order : undefined,
    status_order:
      tableState.orderBy === 'status' ? tableState.order : undefined,
    created_at_order:
      tableState.orderBy === 'created_at' ? tableState.order : undefined,
    updated_at_order:
      tableState.orderBy === 'updated_at' ? tableState.order : undefined,
    id_order: tableState.orderBy === 'id' ? tableState.order : undefined,
    where: {
      _and: [{ deleted_at: { _is_null: true } }, ...searchConditions],
    },
  };
};
