import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import SnackManager from './manager';

const SnackbarManager: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    SnackManager.onSnackbar(({ message, options }) =>
      enqueueSnackbar(message, options)
    );

    return () => {
      SnackManager.cleanUp();
    };
  }, [enqueueSnackbar]);

  return <span />;
};

export default SnackbarManager;
