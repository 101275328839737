import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

type Props = {
  name: string;
  control: Control<any, object>; // Try to remove this any from here
} & TextFieldProps;

const TextFieldRegistered: React.FC<Props> = ({
  name,
  control,
  defaultValue = '',
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => <TextField {...field} {...inputProps} />}
  />
);

export default TextFieldRegistered;
