import { Chip, Typography } from '@mui/material';
import React from 'react';

interface Props {
  preferences: string[];
}

const AppointmentPreferenceTags: React.FC<Props> = ({ preferences }) => {
  return preferences ? (
    <>
      {preferences.map((value: string, index: number) => (
        <Chip
          key={value}
          color="primary"
          size="small"
          sx={{ marginLeft: index > 0 ? 1 : 0, fontSize: 10 }}
          label={value}
        />
      ))}
    </>
  ) : (
    <Typography>N/A</Typography>
  );
};

export default AppointmentPreferenceTags;
