/* eslint-disable react/jsx-no-useless-fragment */
import React, { PropsWithChildren, ReactNode } from 'react';
import usePermission from '../../hooks/usePermissions';

interface Props {
  to: string[];
  fallback?: ReactNode | string;
}

const Restricted: React.FC<PropsWithChildren<Props>> = ({
  children,
  to,
  fallback,
}) => {
  const isAllowed = usePermission(to);

  if (isAllowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default Restricted;
